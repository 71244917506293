import { all } from 'redux-saga/effects'

import createSelectionList from './createSelectionList'
import getSelectionList from './getSelectionList'

function* artificialIntelligence() {
  yield all([createSelectionList(), getSelectionList()])
}

export default artificialIntelligence
