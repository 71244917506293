import { combineReducers } from 'redux'

import isOpen from './isOpen'
import selectedTemplate from './selectedTemplate'
import isAnalysisLoading from './isAnalysisLoading'
import isRefreshNeeded from './isRefreshNeeded'
import isPublishSettingsDialogOpen from './isPublishSettingsDialogOpen'
import defaultPublishName from './defaultPublishName'
import isUniqueNameCheckInProgress from './isUniqueNameCheckInProgress'
import isPublishInProgress from './isPublishInProgress'

export default combineReducers({
  isOpen,
  selectedTemplate,
  isAnalysisLoading,
  isRefreshNeeded,
  isPublishSettingsDialogOpen,
  defaultPublishName,
  isUniqueNameCheckInProgress,
  isPublishInProgress,
})
