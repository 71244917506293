import { useEffect, useMemo, useState } from 'react'
import { callApiAsync } from '@tabeeb/modules/shared/utils/requests'
import { getSelectionListItems } from '../actions'

export default ({ selectionListId, onError }) => {
  const [selectionListItems, setSelectionListItems] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!selectionListId || selectionListId <= 0) {
      return
    }

    setSelectionListItems(null)
    setLoading(true)

    callApiAsync(getSelectionListItems.request({ selectionListId }))
      .then(({ response }) => {
        setSelectionListItems(response.data)
        setLoading(false)
      })
      .catch((e) => {
        if (onError) {
          onError(e)
        }
      })
  }, [onError, selectionListId])

  const options = useMemo(() => {
    if (
      loading ||
      !selectionListItems ||
      selectionListItems.length === 0 ||
      selectionListId !== selectionListItems[0].SelectionListId
    ) {
      return []
    }

    return [
      ...selectionListItems.map((selectionListItem) => ({
        name: selectionListItem.Label,
        value: {
          Id: selectionListItem.Id,
          Value: selectionListItem.Value,
        },
      })),
    ]
  }, [loading, selectionListId, selectionListItems])

  return { loading, options, onError }
}
